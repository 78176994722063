// 定制登录页的定制项目
export const projects = [
  {
    name: '上庄项目',
    details: {
      mainProject: process.env.REACT_APP_JCB_ENV === 'dev' ? '1285' : '1103',
      projectids: [],
      companyid: process.env.REACT_APP_JCB_ENV === 'dev' ? '20005' : '1450808',
      loginbg: '../../assets/images/login/szhang.jpg',
      className: 'login_bg_sz',
    },
  },
  {
    name: '新郑市房屋安全核查系统',
    details: {
      mainProject: process.env.REACT_APP_JCB_ENV === 'dev' ? '1308' : '1255',
      projectids: [],
      companyid: process.env.REACT_APP_JCB_ENV === 'dev' ? '20056' : '1450846',
      loginbg: '../../assets/images/login/szhang.jpg',
      className: 'login_bg_sz',
    },
  },
  {
    name: '天津市宁河区文明城区创建实地检查系统',
    jcb: true,
    details: {
      mainProject: process.env.REACT_APP_JCB_ENV === 'dev' ? '1406' : '1947',
      projectids: [],
      companyid: process.env.REACT_APP_JCB_ENV === 'dev' ? '133' : '1450846',
      loginbg: '../../assets/images/login/tjcc.png',
      className: 'login_bg_tjcc',
      loginToProject: true,
    },
  },
  {
    name: '大兴文明创城',
    details: {
      mainProject: process.env.REACT_APP_JCB_ENV === 'dev' ? '1430' : '2070',
      projectids: [],
      companyid: process.env.REACT_APP_JCB_ENV === 'dev' ? '20136' : '1450966',
      loginToProject: true,
    },
  },
]

// 获取主项目id
export const getMainProjectId = (companyid) => {
  const project = projects.find((v) => v.details.companyid === companyid)
  if (project) {
    return project.details.mainProject
  }
  return null
}

// 是否直接进入项目
export const isLoginToProject = (companyid) => {
  const project = projects.find((v) => v.details.companyid === companyid)
  if (project) {
    return project.details.loginToProject
  }
  return false
}

export const isOnlyJcb = (companyid) => {
  const project = projects.find((v) => v.details.companyid === companyid)
  if (project) {
    return project.jcb || false
  }
  return false
}
